/* You can add global styles to this file, and also import other style files */
@import 'src/variables.scss';

@font-face { font-family: $default-font; font-display: swap; src: url('./assets/fonts/Poppins-Regular.ttf'); }
@font-face { font-family: $default-bold-font; font-display: swap; src: url('./assets/fonts/Poppins-Bold.ttf'); }

ion-app {
  display: flex;
  flex-direction: column;
}

ion-router-outlet {
  flex: 1; /* Allows the router outlet to expand and fill available space */
  display: block; /* Ensure it's block-level if not already */
}

//ion-content { --overflow: auto; overflow: auto; }

ion-content {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  padding: 0; /* Ensure no padding affects layout */
}

.top-header-space {display: block; height: 60px; z-index: -6;}

.default-list {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.breadcrumbs {
  margin-top: 1vh;
  padding-left: 1vh;
  height: auto;
  display: block;

  span {
    cursor: pointer;
    small:hover {
      background: $default-hover-background-color;
      padding:2px;
    }
    a {
      text-decoration: none;
      color: darkslategray;
    }
  }
}

.h1-container {
  min-height: 5vh;
  display: block;
  width: 100%;
}

.description {
  color: $default-font-color;
  font-style: italic;
  font-size: 1rem;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
  padding-top: 1.5vh;
  font-family: $default-font;
}

.item-container {
  text-decoration: none;
  cursor: pointer;
}

a { text-decoration: none !important; }

.default-image-container {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  position: relative;
  ion-skeleton-text {
      position: absolute;
      aspect-ratio: 16/9;
      left: 1%;
      width: 98%;
      height: 100%;
      z-index: 1;
  }
 /* img {
      position: absolute;
      aspect-ratio: 16/9;
      top: 1%;
      left: 1%;
      width: 98%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
  }*/

  ion-img {
    position: absolute;
    aspect-ratio: 16/9;
    top: 1%;
    left: 1%;
    width: 98%;
    height: 100%;
    object-fit: cover; /* Adjust based on your image fit requirements */
    z-index: 0;
}

  ion-skeleton-text.hidden {
      display: none;
  }
}


h1 {
  font-size: 1.3rem !important;
  padding: 0.5rem 1rem 0 1rem !important;
  display: block;
  text-align: center;
  font-family: $default-bold-font;
  text-transform: uppercase;
  color: black;
  margin: 0 auto;
}

ion-img.fadeIn { position: relative; }
.hidden { display: none; }

@keyframes fadeInAnimation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.default-zoom-color:hover { background: $default-hover-background-color }

.zoom-effect { transition: transform .2s; /* Animation */ }

.zoom-effect:hover {
  transform: scale(1.01); /* Scale the image up to 105% of its original size on hover */
  overflow: hidden;
}

/* Small mobile devices */
@media only screen and (min-width: 320px) and (max-width: 480px) { }

/* Large mobile devices and small tablets */
@media only screen and (min-width: 481px) and (max-width: 767px) { }


@media only screen  and (min-width : 600px) {
  h1 { padding: 1.3rem 1rem 0 1rem; }
}

/* Landscape phones */
@media only screen and (min-width: 576px) and (orientation: landscape) { }

/* Tablets and above */
@media only screen and (min-width: 768px) {
  .default-list { width: 100%; }
  .top-header-space {height: 80px;}
  h1 {
    font-size: 1.5rem !important;
  }
}

/* Desktops and above */
@media only screen and (min-width: 992px) {
  .top-header-space { height: 100px; } // header-space === top-header
  .default-list, searchbar-items-list { width: 66.6%; }
}

/* Large desktops */
@media only screen and (min-width: 1200px) { }

/* Specific for iPads */
@media only screen and (min-width: 768px) and (max-width: 834px)
and (min-height: 1024px) and (max-height: 1194px) and (-webkit-min-device-pixel-ratio: 2) {
  .default-list { width: 100%; }
}

/* Specific for iPad Pro 12.9-inch */
@media only screen and (min-width: 1024px) and (max-width: 1024px)
and (min-height: 1366px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .default-list { width: 100%; }
}

/* iPads in landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .default-list { width: 100%; }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
  .default-list { width: 100%; }
}
