:root {
  --sectionA-color: rgba(33, 150, 243, 1.0); /* Blue */
  --sectionB-color: rgba(121, 85, 72, 1.0); /* Brown */
  --sectionC-color: rgba(76, 175, 80, 1.0); /* Green */
  --sectionD-color: rgba(239, 154, 154, 1.0); /* Light Red */
  --sectionE-color: rgba(63, 81, 181, 1.0); /* Indigo */
  --amazon-button-background-color: rgba(47, 79, 79, 1.0); /* Dark Slate Gray */
  --amazon-button-color: rgba(255, 255, 255, 1.0); /* White */
}

$sectionA-background-color: rgba(33, 150, 243, 0.2); /* Light Blue */
$sectionA-font-color: rgba(255, 255, 255, 1.0); /* White */
$sectionB-background-color: rgba(121, 85, 72, 0.2); /* Light Brown */
$sectionB-font-color: rgba(255, 255, 255, 1.0); /* White */
$sectionC-background-color: rgba(76, 175, 80, 0.2); /* Light Green */
$sectionC-font-color: rgba(47, 79, 79, 1.0); /* Dark Slate Gray */
$sectionD-background-color: rgba(239, 154, 154, 0.2); /* Light Pink */
$sectionD-font-color: rgba(47, 79, 79, 1.0); /* Dark Slate Gray */
$sectionE-background-color: rgba(63, 81, 181, 0.2); /* Light Indigo */
$sectionE-font-color: rgba(255, 255, 255, 1.0); /* White */

$footer-background-color: rgba(176, 196, 222, 1.0); /* Light Steel Blue */
$footer-link-color: rgba(72, 61, 139, 1.0); /* Dark Slate Blue */
$footer-copyrights-color: rgba(72, 61, 139, 1.0); /* Dark Slate Blue */

$default-font: 'PoppinsRegular';
$default-font-color: darkslategray;
$default-bold-font: 'PoppinsBold';
$default-background-color: white;
$default-hover-background-color: rgba(192, 212, 235, 0.8);
$default-extract-color: black;

$article-contents-background-color: rgb(248, 246, 246);
$article-cons-background-color: rgb(227, 200, 200);
$article-font-color: black;
$article-pros-background-color: rgb(235, 254, 235);
$article-tip-box-background-color: rgb(226, 231, 233);
$article-tip-box-border-color: #ddd;
$article-product-image-title-color: slategray;

$article-list-item-name-color: darkslategray;

$contact-message-success-background-color: rgb(121, 212, 121);
$contact-message-error-background-color: rgb(225, 109, 109);

$privacy-policy-background-color: white;
$privacy-policy-contents-background-color:rgb(248, 246, 246);
$privacy-policy-font-color: black;
$privacy-policy-subheading-color: darkslategray;

$privacy-policy-component-background-color: black;
$privacy-policy-component-color: white;
$related-article-heading-color: darkslategray;
$related-article-title-color: darkslategray;
$searchbar-items-list-name-color: darkslategray;

$share-buttons-facebook-background-color: #0866FF;
$share-buttons-pinterest-background-color: #BD081C;
$share-buttons-x-twitter-background-color: #000000;
$share-buttons-whatsapp-background-color: #25D366;
$share-buttons-email-background-color: #d6a400;
$share-buttons-email-color: #000000;

$top-header-desktop-menu-background-color: #ffffff;
$top-header-mobile-menu-background-color: #ffffff;
$top-header-slide-menu-background-color: #ffffff;
$top-header-slide-menu-close-button-color: #000000;